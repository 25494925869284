
import { defineComponent, PropType } from 'vue';
import { RawFile } from '@/models/RawFile.model';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'RawFilesCard',
	props: {
		item: {
			required: true,
			type: Object as PropType<RawFile>,
			default: () => ({}),
		},
	},
});
