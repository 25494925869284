import { TreeNode } from '@/models/TreeNode.model';

export function get<T>({
	tree,
	level,
	prop,
}: {
	tree: TreeNode;
	level: number;
	prop?: string;
}): T[] {
	if (level === 0) {
		return ((prop ? [tree[prop]] : [tree]) as unknown) as T[];
	}

	let arr: unknown[] = [];

	tree.children.forEach((childTree) => {
		arr = [...arr, ...get({ tree: childTree, level: level - 1, prop })];
	});

	return arr as T[];
}
