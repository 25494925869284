
import { defineComponent } from 'vue';
import RawFilesFilters from '@/components/rawFiles/RawFilesFilters.vue';
import CGrid from '@/components/shared/CGrid.vue';
import RawFilesCard from '@/components/rawFiles/RawFilesCard.vue';
import { RawFile } from '@/models/RawFile.model';
import RawFilesNotFound from '@/components/rawFiles/RawFilesNotFound.vue';
import { TreeNode } from '@/models/TreeNode.model';
import { t } from '@/utils/utils';
import { BASE_URL_RAW_FILES } from '@/utils/constants.utils';
import { useMeta } from 'vue-meta';

function formatText(node: TreeNode): string {
	const { meta } = node;

	const variable = t(`filters.variable.${meta.variable}Title`);
	const sez = t(`rawFiles.filters.season.${meta.season}`);
	const viz = t('rawFiles.viz');
	const basePeriod = `${t('rawFiles.basePeriod')} ${meta.basePeriod}`;

	const and = t('word.and');

	const scenario = meta.scenario?.toLowerCase();
	const scenarioLocal = t(`rawFiles.${scenario}`);
	const scenarioStr = scenario ? ` ${and} ${scenarioLocal}` : '';

	const model = meta.model.split('-').pop().toLowerCase();
	const modelStr = t(`rawFiles.${model}File`);

	const text = `${variable} - ${sez} ${viz} ${basePeriod}${scenarioStr} (${modelStr})`;
	return text;
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'RawFiles',
	components: { RawFilesNotFound, RawFilesCard, CGrid, RawFilesFilters },
	setup() {
		useMeta({
			title: t('nav.rawFiles'),
		})
		return {
			gridMargin: '16px',
		};
	},
	data() {
		return {
			rawFilesList: [] as RawFile[],
		};
	},
	computed: {
		resultsContString(): string {
			const count = this.rawFilesList?.length;

			if (!Number.isFinite(count)) {
				return '';
			}

			const resultsStr = count > 1 ? this.$t('word.results') : this.$t('word.result');
			return `${count} ${resultsStr}`;
		},
	},
	methods: {
		onApplyFilter(files: TreeNode[]): void {
			this.rawFilesList = files.map(
				(node) =>
					new RawFile({
						link: `${BASE_URL_RAW_FILES}${node.url}`,
						name: node.name,
						text: formatText(node),
						isMulti: node.meta.extension === 'zip',
					})
			);
		},
	},
});
