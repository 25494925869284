
import { defineComponent } from 'vue';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'RawFilesNotFound',
	props: {
		title: {
			required: false,
			type: String,
			default: '',
		},
		message: {
			required: false,
			type: String,
			default: '',
		},
	},
});
