import { ButtonType } from '@/enums/ButtonType.enum';

export class CButtonModel {
	public text: string;
	public type: ButtonType;
	public name?: string;
	public isActive? = false;
	public style?: Record<string, number | string>;

	constructor(obj: CButtonModel) {
		this.type = obj.type;
		this.name = obj.name;
		this.text = obj.text;
		this.isActive = obj.isActive;
		this.style = obj.style;
	}
}
