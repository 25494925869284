export class RawFile {
	public link: string;
	public name: string;
	public text: string;
	public isMulti: boolean;

	constructor(obj: RawFile) {
		this.link = obj.link;
		this.name = obj.name;
		this.text = obj.text;
		this.isMulti = obj.isMulti;
	}
}
