
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue';
import CGrid from '@/components/shared/CGrid.vue';
import { CButtonModel } from '@/models/CButton.model';
import { ButtonType } from '@/enums/ButtonType.enum';
import CButton from '@/components/shared/CButton.vue';
import CCollapse from '@/components/shared/CCollapse.vue';
import CCheckbox from '@/components/shared/CCheckbox.vue';
import { FilterVariable } from '@/models/FilterVariable.model';
import { KeyName } from '@/models/KeyName.model';
import { CButtonRadioModel } from '@/models/CButtonRadio.model';
import CButtonRadio from '@/components/shared/CButtonRadio.vue';
import { get } from '@/utils/rawFiles.utils';
import RawFilesTree from '@/../public/data/rawFiles.data.json';
import { TreeLevel } from '@/enums/TreeLevel.enum';
import { TreeNode } from '@/models/TreeNode.model';
import { TreeMeta } from '@/models/TreeMeta.model';
import { clone, falsy, getCurrentParams, getUniqValues, mergeUrlParams, t } from '@/utils/utils';
import { FilterVariableType } from '@/enums/FilterVariableType.enum';
import { FilterVariableBase } from '@/enums/FilterVariableBase.enum';
import { RawFilesFiltersState } from '@/models/RawFilesFiltersState.model';
import { RawFilesFiltersLabels } from '@/models/RawFilesFiltersLabels.model';
import { RawFilesFiltersErrorState } from '@/models/RawFilesFiltersErrorState.model';
import { RoutePath } from '@/enums/RoutePath.eunm';
import { useRouter } from 'vue-router';

enum ComponentEvent {
	APPLY_FILTER = 'applyFilter',
}

function getFilterDataList({ tree }: { tree: TreeNode }): CButtonModel[] {
	return get<string>({ tree, level: TreeLevel.DATA, prop: 'name' }).map(
		(name) =>
			new CButtonModel({
				text: t(`rawFiles.${name}`),
				name,
				type: ButtonType.FILTER,
				isActive: false,
			})
	);
}

function getFilterSeason({ tree }: { tree: TreeNode }): CButtonRadioModel[] {
	const seasonsOrder = ['d', 'm', 'g'];

	const seasons = getUniqValues<string>(
		get<TreeMeta>({ tree, level: TreeLevel.FILES, prop: 'meta' })
			.filter((meta) => meta.season)
			.map((meta) => meta.season)
	).sort((s1, s2) => seasonsOrder.indexOf(s1) - seasonsOrder.indexOf(s2));

	return seasons.map(
		(season) =>
			new CButtonRadioModel({
				name: 'season',
				value: season,
				label: t(`rawFiles.filters.season.${season}`),
			})
	);
}

const BASE_VARIABLES = Object.values(FilterVariableBase) as string[];

function getFilterVariables({ tree }: { tree: TreeNode }): FilterVariable[] {
	const variables = getUniqValues<string>(
		get<string>({ tree, level: TreeLevel.VARIABLES, prop: 'name' })
	);

	const baseVariables = variables
		.filter((v) => BASE_VARIABLES.includes(v))
		.map((name) => new KeyName({ key: name, name }));

	const indexVariables = variables
		.filter((v) => !BASE_VARIABLES.includes(v))
		.map((name) => new KeyName({ key: name, name }));

	return [
		new FilterVariable({
			type: FilterVariableType.BASE,
			list: baseVariables,
		}),
		new FilterVariable({
			type: FilterVariableType.INDEX,
			list: indexVariables,
		}),
	];
}

function getFilterScenarioList({ tree }: { tree: TreeNode }): CButtonRadioModel[] {
	const scenarioStrList = getUniqValues<string>(
		get<TreeMeta>({ tree, level: TreeLevel.FILES, prop: 'meta' })
			.map((meta) => meta.scenario)
			.filter((scenario) => scenario)
	);

	return scenarioStrList.map(
		(scenario) =>
			new CButtonRadioModel({
				name: 'scenario',
				value: scenario,
				label: scenario,
			})
	);
}

const formatVariablesLabels = (variables: string[]): string => {
	if (!variables?.length) return '';
	return variables.reduce((prev, curr) => `${prev} , ${curr}`);
};

const initSelectState: RawFilesFiltersState = {
	data: '',
	variables: [],
	season: '',
	scenario: '',
};

const initErrorState: RawFilesFiltersErrorState = {
	dataError: '',
	variablesError: '',
	seasonError: '',
	scenarioError: '',
};

const initLabelsState: RawFilesFiltersLabels = {
	dataTitle: t('rawFiles.filters.data.title'),
	dataInfo: t('rawFiles.filters.data.info'),
	dataSelectedLabel: '',

	variablesTitle: t('rawFiles.filters.variables.title'),
	variablesInfo: t('rawFiles.filters.variables.info'),
	variablesSelectedLabel: '',

	seasonTitle: t('rawFiles.filters.season.title'),
	seasonInfo: t('rawFiles.filters.season.info'),
	seasonSelectedLabel: '',

	scenarioTitle: t('rawFiles.filters.scenario.title'),
	scenarioInfo: t('rawFiles.filters.scenario.info'),
	scenarioSelectedLabel: '',
};

/**
 * If variable is not listed here it will be displayed in the end of list.
 */
const variablesOrder = [
	// Base climate variables
	'tas',
	'tasmax',
	'tasmin',
	'pr',
	// Climate indices
	'su',
	'td',
	'txge35',
	'tr',
	'fd',
	'id',
	'gsl5',
	'gsl10',
	'hwfi',
	'hwfid',
	'hwdi',
	'hwdid',
	'rr20',
	'rr30',
	'rx1d',
	'rx5d',
	'cdd',
	'cwd',
	'spei6a',
	'eq',
	'fai',
	'htc',
];

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'RawFilesFilters',
	components: { CButtonRadio, CCheckbox, CCollapse, CButton, CGrid },
	setup(props, context) {
		const router = useRouter();

		const tree = (RawFilesTree as unknown) as TreeNode;

		const dataList = ref<CButtonModel[]>(getFilterDataList({ tree }));
		const seasonList = ref<CButtonRadioModel[]>(getFilterSeason({ tree }));
		const variableList = ref<FilterVariable[]>(getFilterVariables({ tree }));
		const scenarioList = ref<CButtonRadioModel[]>(getFilterScenarioList({ tree }));

		const selectState = reactive<RawFilesFiltersState>({ ...initSelectState });
		const labelsState = reactive<RawFilesFiltersLabels>({ ...initLabelsState });
		const errorState = reactive<RawFilesFiltersErrorState>({ ...initErrorState });

		let isApplyFilterClicked = false;

		const handleErrorState = (): boolean => {
			const state = selectState;
			const errorMsg = t('message.requiredOption');

			const isDataUnselected = dataList.value.length ? falsy(state.data) : false;
			const isSeasonUnselected = seasonList.value.length ? falsy(state.season) : false;
			const isVariablesUnselected = variableList.value.length ? falsy(state.variables) : false;
			const isScenarioUnselected = scenarioList.value.length ? falsy(state.scenario) : false;

			errorState.dataError = isDataUnselected ? errorMsg : '';
			errorState.seasonError = isSeasonUnselected ? errorMsg : '';
			errorState.variablesError = isVariablesUnselected ? errorMsg : '';
			errorState.scenarioError = isScenarioUnselected ? errorMsg : '';

			return (
				isDataUnselected || isSeasonUnselected || isVariablesUnselected || isScenarioUnselected
			);
		};

		const selectSeason = (season: string) => {
			selectState.season = season;
			labelsState.seasonSelectedLabel = t(`rawFiles.filters.season.${season}`);
		};

		const selectScenario = (value: string) => {
			selectState.scenario = value;
			labelsState.scenarioSelectedLabel = value;
		};

		const selectData = (name: string) => {
			selectState.data = name;

			const newTree: TreeNode = {
				...clone(tree),
				children: [tree.children.find((node) => node.name === name)],
			};

			/**
			 * Generate options for new tree
			 */
			seasonList.value = getFilterSeason({ tree: newTree });
			scenarioList.value = getFilterScenarioList({ tree: newTree });
			variableList.value = getFilterVariables({ tree: newTree });

			/**
			 * Select options that are already selected if that options exist.
			 */
			const seasonExists = seasonList.value.find((btn) => btn.value === selectState.season);
			selectState.season = seasonExists ? selectState.season : initSelectState.season;
			labelsState.seasonSelectedLabel = seasonExists
				? selectState.season
				: initLabelsState.seasonSelectedLabel;

			const scenarioExists = scenarioList.value.find((btn) => btn.value === selectState.scenario);
			selectState.scenario = scenarioExists ? selectState.scenario : initSelectState.scenario;
			labelsState.scenarioSelectedLabel = scenarioExists
				? selectState.scenario
				: initLabelsState.scenarioSelectedLabel;

			let varsExist: string[] = [];
			variableList.value.forEach((filterVars) => {
				const { list } = filterVars;
				const selectAgain = list
					.filter((keyName) => selectState.variables.includes(keyName.key))
					.map((keyName) => keyName.key);

				varsExist = [...varsExist, ...selectAgain];
			});
			selectState.variables = varsExist.length ? varsExist : initSelectState.variables;
			labelsState.variablesSelectedLabel = formatVariablesLabels(varsExist);
		};

		const selectVariable = (variable: string, checked: boolean) => {
			if (checked) {
				selectState.variables = [...(selectState.variables || []), variable];
			} else {
				selectState.variables = selectState.variables.filter((v) => v !== variable);
			}

			labelsState.variablesSelectedLabel = formatVariablesLabels(selectState.variables);
		};

		const applyFilter = () => {
			isApplyFilterClicked = true;

			const dataName: string = selectState.data;
			const variableNames: string[] = selectState.variables || [];
			const timeFrequencyMeta: string = selectState.season;
			const scenarioMeta: string = selectState.scenario;

			if (handleErrorState()) {
				return;
			}

			mergeUrlParams({
				router,
				params: { ...selectState },
				path: RoutePath.RAW_FILES,
			});

			const dataNode = get<TreeNode>({ tree, level: TreeLevel.DATA }).find(
				(treeNode) => treeNode.name === dataName
			);

			let files: TreeNode[] = [];
			let foundFiles: TreeNode[] = [];

			let variablesNode: TreeNode;
			let filesNodeList: TreeNode[];

			let isScenarioGood: boolean;
			let isSeasonGood: boolean;

			variableNames.forEach((varName) => {
				variablesNode = dataNode.children.find((childNode) => childNode.name === varName);

				filesNodeList = variablesNode?.children;
				foundFiles = filesNodeList?.filter(({ meta }) => {
					isScenarioGood = scenarioMeta ? meta.scenario === scenarioMeta : true;
					isSeasonGood = timeFrequencyMeta ? meta.season === timeFrequencyMeta : true;

					return isScenarioGood && isSeasonGood;
				});

				files = [...files, ...(foundFiles || [])];
			});

			context.emit(ComponentEvent.APPLY_FILTER, files);
		};

		const orderVariables = ({ list }: { list: KeyName[] }): KeyName[] => {
			let aWeight: number;
			let bWeight: number;

			return clone(list).sort((a, b) => {
				aWeight = variablesOrder.findIndex((vari) => vari === a.name);
				bWeight = variablesOrder.findIndex((vari) => vari === b.name);

				// if index is not listed put it in the end.
				aWeight = aWeight === -1 ? 100 : aWeight;
				bWeight = bWeight === -1 ? 100 : bWeight;

				return aWeight - bWeight;
			});
		};

		onMounted(() => {
			const allFiles = get<TreeNode>({ tree, level: TreeLevel.FILES });
			context.emit(ComponentEvent.APPLY_FILTER, allFiles);

			/**
			 * Sync URL params with filters
			 */
			const currentParams = (getCurrentParams() as unknown) as RawFilesFiltersState;
			if (falsy(currentParams)) {
				return;
			}

			selectData(currentParams.data);
			selectSeason(currentParams.season);
			selectScenario(currentParams.scenario);

			const vars = Array.isArray(currentParams.variables)
				? currentParams.variables
				: [currentParams.variables];
			vars.filter(Boolean).forEach((variable) => {
				selectVariable(variable, true);
			});

			applyFilter();
		});

		watch(selectState, () => {
			if (isApplyFilterClicked) {
				handleErrorState();
			}
		});

		return {
			ButtonType,
			gridMargin: '12px',
			dataList,
			seasonList,
			variableList,
			scenarioList,
			selectData,
			selectVariable,
			selectSeason,
			selectScenario,
			applyFilter,
			orderVariables,
			...toRefs(selectState),
			...toRefs(labelsState),
			...toRefs(errorState),
		};
	},
});
